<script setup lang="ts">
import { InviteSubUserVM } from './InviteSubUserVM'
import { Session } from '~/state'
import { PanelActivator, Button, ALink, Field, Grid, Flex, Stack, ButtonActions, Message } from '~/components/ui'
import { intercomProxy } from '~js/lib/intercom/IntercomProxy'
import Dialog from 'primevue/dialog'
import IconPersonAdd from '@material-symbols/svg-600/outlined/person_add.svg'
import IconHelp from '@material-symbols/svg-600/outlined/help.svg'
import { Tippy } from 'tippy.vue'
import InputText from 'primevue/inputtext'
import Steps from 'primevue/steps'
import { model } from 'vue-observables'
import {
  object,
  pipe,
  string,
  email,
  maxLength,
  minLength,
  trim
} from 'valibot'
import { createInitializer, createValidator } from 'vue-observables/adapters/valibot'

const overrideEnabled = Session.Metrics.HasTagOverrideEnabled === true
let vm = new InviteSubUserVM(overrideEnabled)

const visibleRef = defineModel<boolean>('visible', { default: false })

const ProfileSchema = object({
  email: pipe(
    string(),
    trim(),
    minLength(1, 'Email is required.'),
    maxLength(120),
    email('Invalid email address.')
  )
})

const popupModel = model<{ email: string }>({
  email: ''
}, {
  initializer: createInitializer(ProfileSchema),
  validator: createValidator(ProfileSchema),
  validateOnChange: true,
  validateOnMount: true
})

const emit = defineEmits(['show', 'hide'])

const handleShow = () => {
  emit('show')
  if (Session.IsLimitedAccessTrial) {
    intercomProxy.trackEvent('event-add-user-block')
  }
}
const handleHide = () => {
  emit('hide')
  setTimeout(() => {
    vm = new InviteSubUserVM(overrideEnabled)
  }, 200)
}

watch(() => popupModel.errors.email, error => {
  if (error) {
    vm.step2enabled(false)
  }
})
</script>

<template>
  <PanelActivator v-model:visible="visibleRef" v-slot="{ show, hide, visible, panelRef }">
    <slot name="activator">
      <Button
        @click="show"
        variant="secondary"
        size="small"
      >
        <IconPersonAdd width="1.5em" height="1.5em" /> Add a user...
      </Button>
    </slot>
    <Dialog
      :ref="panelRef"
      v-model:visible="visible.value"
      :draggable="false"
      header="Invite a User"
      modal
      :closeOnEscape="false"
      @show="handleShow"
      @hide="handleHide"
      blockScroll
    >
      <template #header>
        <Flex>
          <span class="p-dialog-title">Invite a User</span>
          <Button
            plain
            rounded
            v-tooltip
            style="align-self: flex-end;"
          >
            <IconHelp />
          </Button>
          <Tippy trigger="focus click">
            <h4>Have a team?</h4>
            <p>Add a sub-user to allow a collaborator or other business unit to create and manage links for you. Sub-user accounts and links can be viewed by you, while your account and links will remain private. Reporting for all sub-accounts will also be conveniently wrapped up and summarized in your master account.</p>
            <i>
              Note: Each additional sub-user will add $50.00/mo. to your bill. Additionally, email addresses can only be associated with one Genius Link account.
            </i>
          </Tippy>
        </Flex>
      </template>

      <Stack v-if="!Session.IsLimitedAccessTrial" space="gap28">
        <Steps
          :activeStep="vm.showStepNumber() - 1"
          @update:activeStep="e => vm.showStepNumber(e + 1)"
          :model="[
            {
              label: 'Enter Email',
              command: () => vm.showStepNumber(1)
            },
            {
              label: 'Confirm',
              command: () => vm.showStepNumber(2),
              disabled: !vm.step2enabled()
            }
          ]"
          :readonly="false"
        />
        <div
          class="popup_content"
          v-if="vm.showInviteUserFirstStep()"
        >

          <form
            id="form-invite-user"
            onsubmit="return false"
          >
            <Field label="Email Address">
              <Grid template="1fr auto" gap="1rem">
                <InputText
                  v-model="popupModel('email').value"
                  placeholder="somebody@example.com"
                />
                <Button
                  @click="vm.showStepNumber(2)"
                  :disabled="Boolean(popupModel.errors.email)"
                >
                  Invite
                </button>
              </Grid>
            </Field>

          </form>
        </div>
        <!-- Confirmation step -->
        <div
          class="popup_content"
          v-if="vm.showInviteUserSecondStep()"
        >
          <div class="popup-description">
            <ol>
              <li>1 sub-user account, $50/mo.</li>
              <li>
                Upon confirming, an invitation will be sent to
                <b>{{ popupModel('email')() }}</b>.
                Do you wish to continue?
              </li>
              <li class="fineprint">
                Note: Once this invitation is accepted, we will begin billing at the rate above, with your first month prorated.
              </li>
            </ol>

            <Message v-if="vm.inviteUserErrorText()" variant="error">
              {{ vm.inviteUserErrorText() }}
            </Message>

            <Message v-if="vm.inviteUserSuccessText()" variant="success">
              {{ vm.inviteUserSuccessText() }}
            </Message>

            <ButtonActions>
              <template v-if="!vm.inviteUserSuccessText()">
                <Button
                  @click="vm.confirmInvitation.call(vm, popupModel('email'))"
                  variant="primary"
                  :loading="vm.inviteUserWait()"
                >
                  Yes, add this user
                </button>
                <Button variant="secondary" @click="hide()">
                  Cancel
                </Button>
              </template>
              <Button v-else variant="primary" @click="hide()">
                Close
              </Button>
            </ButtonActions>
          </div>
        </div>
      </Stack>
      <template v-else>
        <p>Want to add a user to your account? Awesome!</p>
        <p>
          To ensure uninterrupted service, we ask that you
          <ALink to="/account/creditcardinfo?cc-show=1">
            add payment info
          </ALink> to your account first.
          Your trial will still be 100% free.
        </p>
      </template>
    </Dialog>
  </PanelActivator>
</template>

<style scoped>
.popup_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
