import {
  type Observable,
  type Computed,
  observable,
  computed
} from '~/components/utils'
import $ from 'jquery'
import { Segment } from '~js/SegmentProxy'
import { intercomProxy } from '~js/lib/intercom/IntercomProxy'
import { type Field } from 'vue-observables'

export class InviteSubUserVM {
  readonly tagOverrideIsEnabled: Observable<boolean> // only used by minireport footers - doesn't belong in this model

  readonly showStepNumber = observable<1 | 2>(1)
  readonly showInviteUserFirstStep: Computed<boolean>
  readonly showInviteUserSecondStep: Computed<boolean>
  readonly showInviteUserThirdStep: Computed<boolean>

  errorTitle = observable('')
  errorMessage = observable('')
  inviteUserWait = observable(false)
  inviteUserErrorText = observable('')
  inviteUserSuccessText = observable('')
  enableInviteButton = observable(false)
  step2enabled = observable(false)

  constructor(overrideEnabled: boolean) {
    this.tagOverrideIsEnabled = observable(overrideEnabled)

    this.showStepNumber = observable(1)
    this.showInviteUserFirstStep = computed((): boolean => {
      return this.showStepNumber() === 1
    })
    this.showInviteUserSecondStep = computed((): boolean => {
      return this.showStepNumber() === 2
    })
    this.showStepNumber.subscribe((newStep: number): void => {
      if (newStep === 2) {
        this.step2enabled(true)
      }
      if (newStep === 1) {
        this.inviteUserErrorText('')
      }
    })
  }

  trackSubUserInvite(): void {
    try {
      const eventName = 'action-add-sub-user'
      intercomProxy.trackEvent(eventName)
      Segment.track(eventName, {})
    } catch (err) {
      // It's fine, we tried
    }
  }

  confirmInvitation(email: Field<string>): void {
    const emailEncoded = encodeURIComponent(email())
    const inviteUserReqUrl = '/api/v1/team/subaccount-join-link?format=json&email=' + emailEncoded
    this.inviteUserWait(true)
    this.inviteUserErrorText('')
    this.inviteUserSuccessText('')

    void $.ajax({
      type: 'GET',
      url: inviteUserReqUrl,
      success: (): void => {
        this.trackSubUserInvite()
        this.inviteUserSuccessText("Done! We've sent an invite to " + email() + '.')
        this.inviteUserWait(false)
        email('')
      },
      error: (jqXHR): void => {
        if (jqXHR.responseJSON.responseStatus.errors[0].message !== '') {
          this.inviteUserErrorText(jqXHR.responseJSON.responseStatus.errors[0].message)
        }
        this.inviteUserWait(false)
      }
    })
  }
}
